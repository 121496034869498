import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import MaterialIcon from '../../MaterialIcon';

Milestone.propTypes = {
	Title: PropTypes.string,
	Index: PropTypes.number,
	Complete: PropTypes.bool,
	InProgress: PropTypes.bool,
};

function Milestone({ Title, Index, Complete, InProgress }) {
	return (
		<Row className='ProjectRoadmap_Milestone justify-content-center'>
			<Col xs={'auto'} style={{ zIndex: 1 }} className='bg-body border border-4 rounded-3'>
				<div className='fs-2 text-center d-flex align-items-center' style={{ color: Complete ? 'var(--nmd-tk-teal)' : InProgress ? 'var(--nmd-tk-cyan)' : 'var(--nmd-tk-mid)' }}>
					<MaterialIcon Icon={Complete ? 'select_check_box' : InProgress ? 'motion_sensor_active' : 'check_box_outline_blank'} className={'me-2'} />
					{Title ?? `Milestone ${Index}`}
				</div>
			</Col>
		</Row>
	);
}

export default Milestone;
