import '../styles/ComponentStyles/ToolLink.css';
import { NavLink } from 'react-router-dom';
import MaterialIcon from './MaterialIcon';

function ToolLink({ className = '', style, Level, Text, Icon, ToPath }) {
	return (
		<NavLink to={ToPath} className={`ToolLink text-light text-decoration-none ${className}`} style={{ ...style }}>
			<div className={`ToolLink-Label fs-${Level ?? 1} fw-bold w-100 m-0 mb-4 py-3 px-5 lh-1 bg-mid rounded-pill text-uppercase text-center`}>
				{Icon && <MaterialIcon Icon={Icon} className='transform center-vertical' />}
				{Text ?? ''}
			</div>
		</NavLink>
	);
}

export default ToolLink;
