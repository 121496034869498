import { useEffect } from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import { useRouteError } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { Environments, useAnalytics, useEnvironment } from '../App';
import MaterialIcon from '../Components/MaterialIcon';

export default function ErrorPage({ betaTestEnabled }) {
	const error = useRouteError();
	console.error(error);

	const isDevelopmenmt = useEnvironment() === Environments.Development;

	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Error', error: error });
	}, [error]);

	return (
		<Container fluid className='w-100 h-100 p-0 overflow-y-auto scrollbar-none'>
			<Row className='h-auto min-h-100 align-content-center justify-content-center'>
				<Col xs='auto' className='fade-in'>
					<Card bg='light' text='dark' className='text-center' style={{ maxWidth: '35rem' }}>
						<Card.Header className='p-3'>
							<Card.Title>
								<div className='fs-1'>Hello There!</div>
							</Card.Title>
							<Card.Subtitle>
								<Row className='justify-content-center'>
									<Col xs={'auto'}>
										<Badge bg='warning' className='d-flex align-items-center'>
											<MaterialIcon Icon={'error'} className={'me-1'} /> Something Went Wrong
										</Badge>
									</Col>
								</Row>
							</Card.Subtitle>
						</Card.Header>
						<Card.Body>
							<Card.Text>
								I'm always happy to have visitors, but you're not supposed to be here!
								<br />
								If you're here, something didn't go quite right, which isn't ideal.
							</Card.Text>

							<hr className='rounded-pill' />

							<Card.Text>I'm very sorry about all this!</Card.Text>

							<hr className='rounded-pill' />

							{isDevelopmenmt ? (
								<>
									<Card.Text>The following unexpected error has occurred:</Card.Text>
									<pre className='fst-italic font-monospace text-wrap text-danger mb-0'>{error && (error.statusText || error.message)}</pre>
								</>
							) : (
								<Card.Text>An unexpected error has occurred.</Card.Text>
							)}
						</Card.Body>
						<Card.Footer>
							<Row className='justify-content-center p-2'>
								<Col xs={'auto'}>
									<Card.Link href='/' className='brand_font_lg text-primary'>
										Take Me Home
									</Card.Link>
								</Col>
							</Row>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
