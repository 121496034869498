import React from 'react';
import ProjectRoadmap from './ProjectRoadmap/ProjectRoadmap';

function Roadmap_Toolkit() {
	return (
		<ProjectRoadmap
			Title={'Toolkit Roadmap'}
			Milestones={[
				{ Title: 'Grid Designer - Phase 1', Complete: true },

				{ Title: 'Site Hosting Migration', Complete: true, InProgress: true },

				{ Title: 'D&D Stat Calc Migration', Complete: false, InProgress: true },
				{ Title: 'Grid Designer - Phase 2', Complete: false, InProgress: true },

				{ Title: 'D&D Mob Attack Migration', Complete: false, InProgress: false },
				{ Title: 'Grid Designer - Phase 3', Complete: false, InProgress: false },
				{ Title: 'D&D Stat Calc - Redesign', Complete: false, InProgress: false },
				{ Title: 'D&D Mob Attack - Redesign', Complete: false, InProgress: false },
			]}
			CompleteTitle={'Build More Tools'}
		/>
	);
}

export default Roadmap_Toolkit;
