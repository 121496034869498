import { Alert, Badge, Button, Col, Row } from 'react-bootstrap';
import { debug_history } from '../API/Extensions';
import { cloneAsObject } from '../API/Utilities';
import { useEffect, useState } from 'react';
import MaterialIcon from './MaterialIcon';

function DebugConsole() {
	const [typeFilters, setTypeFilters] = useState({});

	const [history, setHistory] = useState({ ...debug_history.data() });

	debug_history.observe((newHistory) => {
		setHistory({ ...newHistory.data() });
	});

	useEffect(() => {}, [history, typeFilters]);

	const getHistory = () => {
		let fullHistory = [];
		const allOff = Object.values(typeFilters).length === 0 || Object.values(typeFilters).filter((tf) => !!tf).length <= 0;

		(allOff || typeFilters['log']) && history?.log?.map((entry) => fullHistory.push({ Type: 'log', ...entry }));
		(allOff || typeFilters['warn']) && history?.warn?.map((entry) => fullHistory.push({ Type: 'warn', ...entry }));
		(allOff || typeFilters['error']) && history?.error?.map((entry) => fullHistory.push({ Type: 'error', ...entry }));
		(allOff || typeFilters['debug']) && history?.debug?.map((entry) => fullHistory.push({ Type: 'debug', ...entry }));
		(allOff || typeFilters['info']) && history?.info?.map((entry) => fullHistory.push({ Type: 'info', ...entry }));

		return fullHistory.sortByProperties([{ prop: 'Timestamp', dir: -1 }]);
	};

	const getVariantByType = (type) => {
		let variant = '';

		switch (type) {
			case 'log':
				variant = 'primary';
				break;
			case 'warn':
				variant = 'warning';
				break;
			case 'error':
				variant = 'danger';
				break;
			case 'debug':
				variant = 'success';
				break;
			case 'info':
				variant = 'info';
				break;
			default:
				variant = 'primary';
				break;
		}
		return variant;
	};

	return (
		<div className='w-100 h-100 d-flex flex-column bg-dark' style={{ pointerEvents: 'all', fontSize: '0.75rem' }}>
			<Row className='px-3 py-2'>
				{Object.keys(history).map((type, index) => {
					let variant = getVariantByType(type);

					return (
						<Col key={index} className='align-self-center px-1'>
							<Button
								size='sm'
								variant={`${!typeFilters[type] ? 'outline-' : ''}${variant}`}
								className='w-100 text-uppercase'
								onClick={(e) => {
									setTypeFilters({ ...typeFilters, [type]: !typeFilters[type] });
								}}
							>
								{type}
							</Button>
						</Col>
					);
				})}

				<Col xs='auto' className='align-self-center px-1'>
					<Button
						size='sm'
						variant='danger'
						className='w-100'
						onClick={(e) => {
							debug_history.clearFromStorage();
						}}
					>
						<MaterialIcon Icon='delete' />
					</Button>
				</Col>
			</Row>

			<Row className='overflow-y-auto flex-grow-1'>
				<Col>
					{getHistory().length > 0 ? (
						getHistory().map((entry, index) => {
							let variant = getVariantByType(entry.Type);

							return (
								<Row key={`${JSON.stringify(cloneAsObject(Object.values(entry)))}`}>
									<Col>
										<Alert className='p-0 m-1' variant={variant}>
											<Row className='justify-content-between'>
												<Col xs='auto'>
													<Badge className={`text-uppercase m-1 bg-${variant} text-bg-${variant}`}>{entry.Type}</Badge>
												</Col>

												<Col xs='auto'>
													<Badge className='m-1 bg-dark text-bg-dark'>{new Date(entry.Timestamp).toLocaleString()}</Badge>
												</Col>
											</Row>
											<pre className={`w-100 m-0 px-2 py-1 text-bg-${variant}`}>{JSON.stringify(cloneAsObject(Object.values(entry.Params)), null, 4)}</pre>
										</Alert>
									</Col>
								</Row>
							);
						})
					) : (
						<Row>
							<Col>
								<Alert className='p-0 m-1' variant='secondary'>
									<pre className='w-100 m-0 px-2 py-1 text-center text-uppercase'>No Console History To Display</pre>
								</Alert>
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		</div>
	);
}

export default DebugConsole;
