import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, FormCheck, FormControl, FormLabel, InputGroup, Row } from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import FormRange from 'react-bootstrap/esm/FormRange';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';
import { generateRandomElementId, toNumber, toStep } from '../../API/Utilities';

PanelInput.propTypes = {
	Type: PropTypes.oneOf(['text', 'range', 'number', 'switch']).isRequired,
	InputOptions: PropTypes.object,
	HandleValueChange: PropTypes.func,
};

function PanelInput({ className = '', style, Type, DefaultValue = '', CurrentValue, InputOptions = {}, Label, HandleValueChange }) {
	const [inputId, inputId_set] = useState();

	useEffect(() => {
		inputId_set(generateRandomElementId());
	}, []);

	const processValue = useCallback(
		(newValue) => {
			let val = newValue;
			if (InputOptions.step !== undefined) {
				val = toStep(val, InputOptions.step);
			}
			return val;
		},
		[InputOptions]
	);

	const valueChanged = (newValue) => {
		let val = processValue(newValue);
		inputValue_set(val);
		HandleValueChange && typeof HandleValueChange === 'function' && HandleValueChange(val);
	};

	const [inputValue, inputValue_set] = useState(processValue(CurrentValue ?? DefaultValue));

	useEffect(() => {
		inputValue_set(processValue(CurrentValue ?? DefaultValue));
	}, [CurrentValue, DefaultValue, processValue]);

	return (
		<Row className={`my-3 ${className}`} style={{ ...style }}>
			<Col>
				<FormLabel className='w-100 m-0 my-1 px-1' htmlFor={inputId}>
					<Row className='justify-content-between'>
						<Col xs={'auto'}>
							<span className='user-select-none'>{Label}</span>
						</Col>
						<Col xs={'auto'}>
							{(Type === 'range' || Type === 'number') && (
								<FormControl
									id={inputId}
									type={'number'}
									className='w-auto py-0 px-2 border-0 text-end bg-transparent text-light rounded-pill focus-ring focus-ring-light'
									{...InputOptions}
									value={inputValue}
									onChange={(e) => {
										inputValue_set(e.target.value);
									}}
									onBlur={(e) => {
										let val = toNumber(e.target.value);
										if (val === undefined) {
											val = DefaultValue;
										} else if (InputOptions.min !== undefined && InputOptions.min > val) {
											val = InputOptions.min;
										} else if (InputOptions.max !== undefined && InputOptions.max < val) {
											val = InputOptions.max;
										}
										valueChanged(val);
									}}
								/>
							)}

							{Type === 'switch' && (
								<FormCheck type='switch'>
									<FormCheckInput
										id={inputId}
										className='focus-ring focus-ring-light'
										type='checkbox'
										role='switch'
										checked={inputValue}
										{...InputOptions}
										onChange={(e) => valueChanged(e.target.checked)}
									/>
								</FormCheck>
							)}
						</Col>
					</Row>
				</FormLabel>

				{Type === 'range' && (
					<InputGroup className='bg-dark rounded-pill border overflow-hidden'>
						<InputGroupText className='bg-dark text-light rounded-pill border-0'>{InputOptions.min}</InputGroupText>
						<FormRange className='bg-dark border-0 rounded-pill' type={Type} {...InputOptions} value={inputValue} onChange={(e) => valueChanged(e.target.value)} />
						<InputGroupText className='bg-dark text-light rounded-pill border-0'>{InputOptions.max}</InputGroupText>
					</InputGroup>
				)}

				{Type === 'text' && (
					<InputGroup className='bg-dark rounded-pill border overflow-hidden'>
						<FormControl
							id={inputId}
							className='bg-dark border-0 rounded-pill'
							type={Type}
							placeholder={`Enter ${Label} . . .`}
							{...InputOptions}
							value={inputValue}
							onChange={(e) => valueChanged(e.target.value)}
						/>
					</InputGroup>
				)}
			</Col>
		</Row>
	);
}

export default PanelInput;
