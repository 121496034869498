import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Badge, Col, Container, Modal, Navbar, Row, Stack } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import Logo from './Logo';
import MaterialIcon from './MaterialIcon';
import '../styles/ComponentStyles/TopMenu.css';
import UserIcon from './UserIcon';
import DebugConsole from './DebugConsole';
import { Environments, useAnalytics, useEnvironment, useReadDocumentTitle, useRemoteConfigState } from '../App';
import Heading from './Heading';

const projectRoadmap = {};

export const useProjectRoadmap = (newProjectRoadmap) => {
	useEffect(() => {
		projectRoadmap.current = newProjectRoadmap;

		return () => {
			projectRoadmap.current = undefined;
		};
	}, [newProjectRoadmap]);

	return projectRoadmap.current;
};

function TopMenu({ userProfile, userProfilePrivate }) {
	const [expanded, setExpanded] = useState();
	const navigate = useNavigate();

	const location = useLocation();

	useEffect(() => {
		setExpanded(false);
	}, [location]);

	const [showDebugConsole, setShowDebugLog] = useState(false);

	const betaTestEnabled = useRef(useRemoteConfigState.beta_test);

	const documentTitleSplit = useReadDocumentTitle().split(' | ');
	const pageTitle = documentTitleSplit[documentTitleSplit.length - 1].trim();

	const [showProjectRoadmap, showProjectRoadmap_set] = useState(false);
	const handlePageTitleClick = useCallback(() => {
		if (projectRoadmap && projectRoadmap.current) {
			showProjectRoadmap_set(true);
			logEvent(useAnalytics, 'page_view', { page_name: 'Project Roadmap', roadmap_name: pageTitle });
		}
	}, [pageTitle]);

	const isDevelopmenmt = useEnvironment() === Environments.Development;

	return (
		<>
			<Modal show={showProjectRoadmap} fullscreen={'lg-down'} onHide={() => showProjectRoadmap_set(false)} style={{ zIndex: 1000000 }}>
				<Modal.Header closeButton>
					<Modal.Title>{pageTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{projectRoadmap && projectRoadmap.current}</Modal.Body>
			</Modal>

			<Navbar fixed='top' expand='xs' className={'p-0 top_navbar user-select-none ' + (expanded ? 'top_navbar_opened' : 'top_navbar_closed')} onToggle={(e) => setExpanded(!expanded)}>
				<Container fluid className='transform bg-secondary w-100 px-0' style={{ height: '65px', zIndex: 1020, '--top': '0px' }}></Container>
				<Container fluid className='bg-secondary top_navbar_buttons w-100 px-0' style={{ maxWidth: '1200px', height: '65px', zIndex: 1030 }}>
					<Row className='w-100 h-100 m-0 justify-content-center flex-nowrap'>
						<Col className='h-100 px-0'>
							<Navbar.Brand className='h-100 m-0 p-0'>
								<NavLink className='navbar-brand h-100 m-0 d-flex align-items-center' to='/'>
									<Logo />
									<span className='text-light d-none d-md-inline-block'>NoahMillerDesign</span>
								</NavLink>
							</Navbar.Brand>
						</Col>

						<Col xs={'auto'} className='h-100 px-0 d-flex align-items-center'>
							{pageTitle && (
								<Navbar.Text>
									<Badge onClick={(e) => handlePageTitleClick()} bg='dark' className='rounded-pill cursor-pointer px-3 py-2'>
										<h1 className='h6 m-0 w-100 text-truncate'>{pageTitle}</h1>
									</Badge>
								</Navbar.Text>
							)}
						</Col>

						<Col className='h-100 px-0 d-flex align-items-center justify-content-end'>
							{betaTestEnabled && isDevelopmenmt && (
								<Badge onClick={() => setShowDebugLog(!showDebugConsole)} bg='accent' className='position-relative rounded-circle' style={{ width: '1.5rem', height: '1.5rem' }}>
									<MaterialIcon Icon='bug_report' className={'transform center-both fs-5'} />
								</Badge>
							)}
							<Navbar.Toggle style={{ width: '55px' }}>
								<MaterialIcon Icon={expanded ? 'close' : 'menu'} className={'text-white top_menu_toggle ' + (expanded ? 'top_menu_opened' : 'top_menu_closed')} />
							</Navbar.Toggle>
						</Col>
					</Row>
				</Container>
				<Navbar.Collapse className={'bg-secondary p-0 top_menu ' + (expanded ? 'top_menu_opened' : 'top_menu_closed')}>
					<Container className='top_menu_content overflow-y-auto scrollbar-none px-4 pt-0 pb-4 mb-4'>
						{projectRoadmap && projectRoadmap.current && (
							<Row className='py-1'>
								<Col className='text-light'>
									<Row>
										<Col className={'text-center'}>
											<MaterialIcon Icon={'arrow_upward'} className={'fs-3 hovering'} style={{ '--hovering-duration': '0.75s' }} />
										</Col>
									</Row>

									<Row>
										<Col className='text-center lh-1'>Click to View Project Roadmap</Col>
									</Row>
								</Col>
							</Row>
						)}

						<Row>
							<Col>
								<hr className='border-2' />
							</Col>
						</Row>

						<Row>
							<Col>
								<Stack gap={5}>
									<Row>
										<Col>
											<NavLink to={'/'} className={`text-light text-decoration-none`}>
												<div className={`fs-3 fw-bold w-100 text-start d-flex align-items-center`}>
													<MaterialIcon Icon={'service_toolbox'} className='me-2' />
													<span>Toolkit Overview</span>
												</div>
											</NavLink>
										</Col>
									</Row>

									<Row>
										<Col>
											<Stack gap={3}>
												<Row>
													<Col>
														<Heading Text='Active Tools' Level={4} className={'bg-body text-start'} />
													</Col>
												</Row>

												<Row>
													<Col>
														<NavLink to={'grid/designer'} className={`text-light text-decoration-none`}>
															<div className={`fs-3 fw-bold w-100 text-start d-flex align-items-center`}>
																<MaterialIcon Icon={'background_dot_small'} className='me-2' />
																<span>Grid Designer</span>
															</div>
														</NavLink>
													</Col>
												</Row>
											</Stack>
										</Col>
									</Row>

									<Row>
										<Col>
											<Stack gap={3}>
												<Row>
													<Col>
														<Heading Text='Upcoming Tools' Level={4} className={'bg-body text-start'} />
													</Col>
												</Row>

												<Row>
													<Col>
														<NavLink to={'dnd/stat-calc'} className={`text-light text-decoration-none`}>
															<div className={`fs-3 fw-bold w-100 text-start d-flex align-items-center`}>
																<MaterialIcon Icon={'calculate'} className='me-2' />
																<span>D&D Stat Calculator</span>
															</div>
														</NavLink>
													</Col>
												</Row>

												<Row>
													<Col>
														<NavLink to={'dnd/mob-attacker'} className={`text-light text-decoration-none`}>
															<div className={`fs-3 fw-bold w-100 text-start d-flex align-items-center`}>
																<MaterialIcon Icon={'destruction'} className='me-2' />
																<span>D&D Mob Attacker</span>
															</div>
														</NavLink>
													</Col>
												</Row>
											</Stack>
										</Col>
									</Row>
								</Stack>
							</Col>
						</Row>

						{userProfile && userProfilePrivate && (
							<Row
								onClick={() => {
									navigate('/account');
								}}
							>
								<Col xs='auto'>
									<UserIcon photoUrl={userProfile.User_Photo_URL} style={{ height: '3rem' }} className={'border-2 text-light'} />
								</Col>
								<Col>
									{userProfile && userProfile.User_Display_Name && (
										<div
											className='text-body h-50'
											style={{
												lineHeight: '2.25rem',
											}}
										>
											{userProfile.User_Display_Name}
										</div>
									)}

									{userProfilePrivate && userProfilePrivate.User_Email && (
										<div
											className='text-body-secondary h-50'
											style={{
												fontSize: '0.75rem',
											}}
										>
											{userProfilePrivate.User_Email}
										</div>
									)}
								</Col>
							</Row>
						)}
					</Container>
				</Navbar.Collapse>
			</Navbar>

			{showDebugConsole && (
				<div className='position-fixed  w-100' style={{ zIndex: 10000010, top: 65, left: 0, height: 'calc(100vh - 65px)' }}>
					<DebugConsole />
				</div>
			)}
		</>
	);
}

export default TopMenu;
