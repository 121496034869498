import React, { cloneElement, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/ComponentStyles/FloatingContent.css';
import FloatingContent from './FloatingContent';
import { toggleBodyClass } from '../../API/Utilities';

FloatingZone.propTypes = {
	Fullscreen: PropTypes.bool,
	TouchOnly: PropTypes.bool,
	EdgeBuffer: PropTypes.number,
	Floaters: PropTypes.arrayOf(PropTypes.element),
};

function FloatingZone({ className = '', style, Fullscreen = false, TouchOnly = true, EdgeBuffer = 16, Floaters = [] }) {
	const zoneRef = useRef();

	const [snapping, snapping_set] = useState(false);
	const [holding, holding_set] = useState();
	const [holdingFloater, holdingFloater_set] = useState();
	const [contactPosition, contactPosition_set] = useState();

	const handleResetState = useCallback(() => {
		snapping_set(false);
		holding_set(undefined);
		holdingFloater_set(undefined);
		contactPosition_set(undefined);
	}, []);

	const waitingTimeout = useRef();

	const waitForHold = (delay, key, floaterEl, snaps, singleSnap) => {
		if (holding === undefined) {
			waitingTimeout.current = setTimeout(() => {
				holding_set(key);
				holdingFloater_set({ element: floaterEl, snaps: snaps, singleSnap: singleSnap });
				toggleBodyClass('overscroll-behavior-y-contain', true);
			}, delay);
		}
	};

	const handleContactEnd = useCallback(() => {
		waitingTimeout.current && clearTimeout(waitingTimeout.current);
		toggleBodyClass('overscroll-behavior-y-contain', false);

		if (contactPosition && holdingFloater && holdingFloater.snaps && holdingFloater.snaps.length > 0) {
			let holdingElBounds = holdingFloater.element.getBoundingClientRect();
			let zoneBounds = zoneRef.current.getBoundingClientRect();

			let edgeOffsets = [
				{ name: 'top', value: contactPosition.top - (zoneBounds.top + EdgeBuffer + holdingElBounds.width / 2) },
				{ name: 'bottom', value: zoneBounds.bottom - EdgeBuffer - holdingElBounds.width / 2 - contactPosition.top },
				{ name: 'left', value: contactPosition.left - (zoneBounds.left + EdgeBuffer + holdingElBounds.width / 2) },
				{ name: 'right', value: zoneBounds.right - EdgeBuffer - holdingElBounds.width / 2 - contactPosition.left },
			].sortByProperties([{ prop: 'value' }]);

			let snapPosition = { ...contactPosition };

			let stopSnapping = false;
			edgeOffsets.forEach((offset) => {
				if (!stopSnapping && holdingFloater.snaps.includes(offset.name)) {
					switch (offset.name) {
						case 'top':
							if (!holdingFloater.snaps.includes('bottom') || offset.value < edgeOffsets.filter((eo) => eo.name === 'bottom')[0].value)
								snapPosition.top = contactPosition.top - offset.value;
							break;

						case 'bottom':
							if (!holdingFloater.snaps.includes('top') || offset.value < edgeOffsets.filter((eo) => eo.name === 'top')[0].value) snapPosition.top = contactPosition.top + offset.value;
							break;

						case 'left':
							if (!holdingFloater.snaps.includes('right') || offset.value < edgeOffsets.filter((eo) => eo.name === 'right')[0].value)
								snapPosition.left = contactPosition.left - offset.value;
							break;

						case 'right':
							if (!holdingFloater.snaps.includes('left') || offset.value < edgeOffsets.filter((eo) => eo.name === 'left')[0].value)
								snapPosition.left = contactPosition.left + offset.value;
							break;

						default:
							break;
					}

					stopSnapping = holdingFloater.singleSnap;
				}
			});

			if (contactPosition.top !== snapPosition.top || contactPosition.left !== snapPosition.left) {
				snapping_set(true);
				contactPosition_set(snapPosition);

				setTimeout(() => {
					handleResetState();
				}, 500);
			} else {
				handleResetState();
			}
		} else {
			handleResetState();
		}
	}, [contactPosition, holdingFloater, EdgeBuffer, handleResetState]);

	const handleContactMove = (position) => {
		if (holdingFloater && zoneRef.current) {
			let filteredPosition = position;
			let holdingElBounds = holdingFloater.element.getBoundingClientRect();
			let zoneBounds = zoneRef.current.getBoundingClientRect();

			// Filter Top
			filteredPosition.top = Math.max(filteredPosition.top, zoneBounds.top + EdgeBuffer + holdingElBounds.width / 2);
			// Filter Bottom
			filteredPosition.top = Math.min(filteredPosition.top, zoneBounds.bottom - EdgeBuffer - holdingElBounds.width / 2);
			// Filter Left
			filteredPosition.left = Math.max(filteredPosition.left, zoneBounds.left + EdgeBuffer + holdingElBounds.width / 2);
			// Filter Right
			filteredPosition.left = Math.min(filteredPosition.left, zoneBounds.right - EdgeBuffer - holdingElBounds.width / 2);

			contactPosition_set(filteredPosition);
		}
	};

	return (
		<div
			ref={(el) => (zoneRef.current = el)}
			className={`FloatingZone ${holding !== undefined ? 'holding' : ''} position-${Fullscreen ? 'fixed' : 'absolute'} transform ${className}`}
			style={{ pointerEvents: 'none', zIndex: 1000000, '--top': 0, '--bottom': 0, '--left': 0, '--right': 0, '--edge-buffer': `calc(${EdgeBuffer} * 1px)`, ...style }}
			onMouseMove={(e) => {
				if (!TouchOnly && holding !== undefined) {
					let zonePosition = e.currentTarget.getBoundingClientRect();
					handleContactMove({ top: e.pageY - zonePosition.top, left: e.pageX - zonePosition.left });
				}
			}}
			onTouchMove={(e) => {
				if (holding !== undefined) {
					let zonePosition = e.currentTarget.getBoundingClientRect();
					let touch = e.touches.item(0);
					handleContactMove({ top: touch.pageY - zonePosition.top, left: touch.pageX - zonePosition.left });
				}
			}}
			onMouseUp={(e) => handleContactEnd()}
			onTouchEnd={(e) => handleContactEnd()}
			onMouseLeave={(e) => handleContactEnd()}
		>
			{Floaters &&
				Floaters.length > 0 &&
				Floaters.map((floater, idx) => {
					return cloneElement(floater, {
						key: idx,
						TouchOnly: true,
						CurrentHolding: holding === idx,
						CurrentPosition: contactPosition,
						Snapping: snapping,
						HandleContactStart: (delay, el, snaps, singleSnap) => waitForHold(delay, idx, el, snaps, singleSnap),
					});
				})}

			{/* <div className='FloatingZoneInfo transform center-both pe-none user-select-none'>Drop Anywhere in Zone</div> */}
		</div>
	);
}

FloatingZone.Floater = FloatingContent;

export default FloatingZone;
