import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { logEvent } from 'firebase/analytics';
import { useAnalytics, useTitle } from '../App';
import PanelMenu from '../Components/PanelMenu/PanelMenu';
import Grid from '../Components/Grid/Grid';
import { toNumber } from '../API/Utilities';
import ViewerWindow from '../Components/ViewerWindow';
import { Breakpoints, useBreakpoint, useContentBounds } from '../Layouts/Main';
import { MouseButtonMap, MouseDownState } from '../API/Extensions';
import PaletteMenu from '../Components/PaletteMenu/PaletteMenu';
import { useProjectRoadmap } from '../Components/TopMenu';
import ProjectRoadmaps from '../Components/ProjectRoadmaps/_AllProjectRoadmaps';

const gridOptions_default = { stagger_rows: false, vertical_rows: false, rows: 16, columns: 16 };
const cellOptions_default = { radius: 0, show_coords: false, coord_style: '0' };

function GridDesigner() {
	useTitle('Grid Designer');
	useProjectRoadmap(<ProjectRoadmaps.GridDesigner />);
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Beading' });
	}, []);

	const { contentBounds } = useContentBounds();
	const breakpoint = useBreakpoint();

	const [formDirty, formDirty_set] = useState(false);

	const [gridOptions, gridOptions_set] = useState(gridOptions_default);
	const [cellOptions, cellOptions_set] = useState(cellOptions_default);

	const getAutoGridCells = useCallback(() => {
		let bounds = contentBounds;
		const defaultCellSize = 36;
		const autoRows = Math.floor(bounds.height / defaultCellSize);
		const autoColumns = Math.floor(bounds.width / defaultCellSize);
		return { autoRows: Math.min(autoRows, gridOptions_default.rows), autoColumns: Math.min(autoColumns, gridOptions_default.columns) };
	}, [contentBounds]);

	const handleReset = useCallback(() => {
		formDirty_set(false);
		cellOptions_set(cellOptions_default);
		const { autoRows, autoColumns } = getAutoGridCells();
		gridOptions_set({ ...gridOptions_default, rows: autoRows, columns: autoColumns });
	}, [getAutoGridCells]);

	const handleGridOptionsUpdate = (options) => {
		formDirty_set(true);
		gridOptions_set(options);
	};

	const handleCellOptionsUpdate = (options) => {
		formDirty_set(true);
		cellOptions_set(options);
	};

	useEffect(() => {
		if (contentBounds && !formDirty) {
			const { autoRows, autoColumns } = getAutoGridCells();
			if (autoRows !== gridOptions.rows || autoColumns !== gridOptions.columns) {
				setTimeout(() => {
					gridOptions_set({ ...gridOptions, rows: autoRows, columns: autoColumns });
				}, 1000);
			}
		}
	}, [contentBounds, formDirty, gridOptions, getAutoGridCells]);

	const [paletteSwatches, paletteSwatches_set] = useState([]);
	const [activeSwatch, activeSwatch_set] = useState();

	const handlePaletteChange = ({ paletteSwatches, activeSwatch }) => {
		paletteSwatches_set(paletteSwatches);
		activeSwatch_set(activeSwatch);
	};

	const [cellStates, cellStates_set] = useState({});
	const handleCellContact = useCallback(
		({ cellId, xIndex, yIndex }) => {
			let cellIdentifier = getCellIdentifier({ cellId, xIndex, yIndex });
			let newCellStates = {};
			newCellStates[cellIdentifier] = (activeSwatch && activeSwatch.id) ?? undefined;

			if (cellStates[cellIdentifier] !== newCellStates[cellIdentifier]) {
				cellStates_set({ ...cellStates, ...newCellStates });
			}
		},
		[cellStates, activeSwatch]
	);

	const handleClearCellStates = () => {
		cellStates_set({});
	};

	const getCellIdentifier = ({ cellId, xIndex, yIndex }) => cellId ?? `cell_body_x${xIndex}-y${yIndex}`;

	const handleFillClick = useCallback(() => {
		if (activeSwatch && activeSwatch.id) {
			let newCellStates = {};
			let cellStatesForNonActive = {};

			Array.from({ length: gridOptions_default.rows }).map((item, xIndex, arr) => {
				return Array.from({ length: gridOptions_default.columns }).map((item, yIndex, arr) => {
					let cellIdentifier = getCellIdentifier({ xIndex, yIndex });
					if (cellStates[cellIdentifier] === undefined) {
						newCellStates[cellIdentifier] = activeSwatch.id;
					} else if (cellStates[cellIdentifier] !== activeSwatch.id) {
						cellStatesForNonActive[cellIdentifier] = cellStates[cellIdentifier];
					}

					return cellIdentifier;
				});
			});

			if (Object.keys(newCellStates).length === 0) {
				// There were no empty cells, so clear all cells of the active color
				cellStates_set({ ...cellStatesForNonActive });
			} else {
				// There were empty cells, so fill them with the active color
				cellStates_set({ ...cellStates, ...newCellStates });
			}
		}
	}, [cellStates, activeSwatch]);

	const cellTemplate = useCallback(
		({ xIndex, yIndex, rowIndex, columnIndex, cellNumber, xCount, yCount }) => {
			let cellState = cellStates[getCellIdentifier({ xIndex, yIndex })];
			let cellSwatch = cellState && paletteSwatches.find((p) => p.id === cellState);
			let cellColor = cellSwatch && cellSwatch.color;
			return (
				<div
					key={getCellIdentifier({ xIndex, yIndex })}
					id={getCellIdentifier({ xIndex, yIndex })}
					className={`w-100 h-100`}
					style={{ '--cell-color': cellColor !== undefined ? cellColor : 'var(--nmd-tk-body)', backgroundColor: 'var(--cell-color)' }}
					onMouseDown={(e) => {
						if (e.button === MouseButtonMap.LeftButton) {
							handleCellContact({ xIndex, yIndex });
						}
					}}
					onMouseOver={() => {
						if (MouseDownState[MouseButtonMap.LeftButton]) {
							handleCellContact({ xIndex, yIndex });
						}
					}}
					onTouchStart={(e) => {
						if (e.touches.length === 1) {
							handleCellContact({ xIndex, yIndex });
						}
					}}
					onTouchMove={(e) => {
						if (e.touches.length === 1) {
							let touch = e.touches.item(0);
							handleCellContact({ cellId: document.elementFromPoint(touch.pageX, touch.pageY).id });
						}
					}}
				>
					{/* {cellNumber} */}
				</div>
			);
		},
		[paletteSwatches, handleCellContact, cellStates]
	);

	return (
		<>
			<Container fluid className='w-100 h-100 p-0'>
				<PaletteMenu HandlePaletteChange={handlePaletteChange} HandleFillClick={handleFillClick} MaxSwatches={9} />

				<ViewerWindow
					DefaultZoom={0.95}
					ContentTemplate={
						<Grid
							Rows={toNumber(gridOptions.rows)}
							Columns={toNumber(gridOptions.columns)}
							StaggerRows={gridOptions.stagger_rows}
							VerticalRows={gridOptions.vertical_rows}
							CellOptions={{
								height: cellOptions.height,
								width: cellOptions.width,
								radius: cellOptions.radius,
								show_coords: cellOptions.show_coords,
								coord_style: cellOptions.coord_style,
							}}
							CellTemplate={cellTemplate}
						/>
					}
				/>

				<PanelMenu
					AlwaysOpen={breakpoint === Breakpoints.xxl}
					MenuOpen={breakpoint >= Breakpoints.md}
					ClickOutClose={breakpoint <= Breakpoints.md}
					HeaderText={'Settings'}
					style={{ '--top-buffer': 'calc(50px + 1rem)', '--bottom-buffer': 'calc(50px + 1rem + 1.25rem)' }}
					ButtonStartingPosition={{ top: 'calc(100% - 2.5rem)', left: 'calc(100% - 2.5rem)' }}
					ContentTemplate={
						<>
							<PanelMenu.Input
								Label={'Stagger Rows'}
								Type={'switch'}
								DefaultValue={gridOptions_default.stagger_rows}
								CurrentValue={gridOptions.stagger_rows}
								HandleValueChange={(val) => handleGridOptionsUpdate({ ...gridOptions, stagger_rows: val })}
							/>

							<PanelMenu.Input
								Label={'Vertical Rows'}
								Type={'switch'}
								DefaultValue={gridOptions_default.vertical_rows}
								CurrentValue={gridOptions.vertical_rows}
								HandleValueChange={(val) => handleGridOptionsUpdate({ ...gridOptions, vertical_rows: val })}
							/>

							<PanelMenu.Divider />

							<PanelMenu.Input
								Label={'Rows'}
								Type={'range'}
								InputOptions={{ min: 3, max: gridOptions_default.rows, step: 1 }}
								DefaultValue={Math.round(gridOptions_default.rows / 2)}
								CurrentValue={gridOptions.rows}
								HandleValueChange={(val) => handleGridOptionsUpdate({ ...gridOptions, rows: val })}
							/>

							<PanelMenu.Input
								Label={'Columns'}
								Type={'range'}
								InputOptions={{ min: 3, max: gridOptions_default.columns, step: 1 }}
								DefaultValue={Math.round(gridOptions_default.columns / 2)}
								CurrentValue={gridOptions.columns}
								HandleValueChange={(val) => handleGridOptionsUpdate({ ...gridOptions, columns: val })}
							/>

							<PanelMenu.Divider />

							<PanelMenu.Collapse
								className='mt-n1 mb-0'
								CurrentOpen={cellOptions.show_coords}
								ContentTemplate={
									<>
										<PanelMenu.RadioButton
											Label={'Coordinate Style'}
											Options={[
												{ label: 'R | C', value: '0' },
												{ label: 'Num', value: '1' },
											]}
											size={'sm'}
											DefaultValue={cellOptions_default.coord_style}
											CurrentValue={cellOptions.coord_style}
											HandleValueChange={(val) => handleCellOptionsUpdate({ ...cellOptions, coord_style: val })}
										/>
									</>
								}
							/>

							<PanelMenu.Input
								Label={'Show Coordinates'}
								Type={'switch'}
								DefaultValue={cellOptions_default.show_coords}
								CurrentValue={cellOptions.show_coords}
								HandleValueChange={(val) => handleCellOptionsUpdate({ ...cellOptions, show_coords: val })}
							/>

							<PanelMenu.Divider />

							<PanelMenu.Input
								Label={'Radius'}
								Type={'range'}
								InputOptions={{ min: 0, max: 3, step: 1.0 }}
								DefaultValue={cellOptions_default.radius}
								CurrentValue={cellOptions.radius}
								HandleValueChange={(val) => handleCellOptionsUpdate({ ...cellOptions, radius: val })}
							/>

							<PanelMenu.Divider />

							<PanelMenu.Button Label={'Reset Settings'} className='text-light' variant={'outline-secondary'} size={'sm'} HandleButtonClick={() => handleReset()} />
						</>
					}
					FooterTemplate={
						<>
							<PanelMenu.Button Label={'Clear Design'} className='text-light' variant={'outline-danger'} size={'sm'} HandleButtonClick={() => handleClearCellStates()} />
						</>
					}
				/>
			</Container>
		</>
	);
}

export default GridDesigner;
