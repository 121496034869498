import { Accordion, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { scrollElementIntoView } from '../../API/Utilities';
import { useEffect, useRef, useState } from 'react';

PanelCollapse.propTypes = {
	Label: PropTypes.string,
	ContentTemplate: PropTypes.element,
	DefaultOpen: PropTypes.bool,
};

function PanelCollapse({ className = '', style, Label = '', ContentTemplate, DefaultOpen = false, CurrentOpen }) {
	const accordionRef = useRef();
	const [open, open_set] = useState(DefaultOpen);

	useEffect(() => {
		open_set(CurrentOpen);
	}, [CurrentOpen]);

	return (
		<Row className={`my-3 ${className}`} style={{ ...style }}>
			<Col>
				<Accordion
					ref={accordionRef}
					flush
					alwaysOpen
					defaultActiveKey={DefaultOpen ? ['0'] : []}
					activeKey={open ? ['0'] : []}
					className={`bg-transparent focus-ring`}
					onSelect={(eventKey, e) => {
						open_set(!open ?? true);
						accordionRef.current && setTimeout(() => scrollElementIntoView({ el: accordionRef.current }), 400);
					}}
				>
					<Accordion.Item eventKey='0' className='bg-transparent'>
						{Label && (
							<Accordion.Button className='ps-1 py-0 user-select-none bg-transparent focus-ring focus-ring-none' style={{ '--nmd-tk-accordion-border-width': 0 }}>
								{Label}
							</Accordion.Button>
						)}
						<Accordion.Body className='bg-transparent px-0 py-0 mb-n3'>{ContentTemplate}</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Col>
		</Row>
	);
}

export default PanelCollapse;
