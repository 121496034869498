import '../styles/ComponentStyles/Heading.css';
import MaterialIcon from './MaterialIcon';

function Heading({ className = '', style, Level, Text, Icon }) {
	return (
		<div className={`Heading fs-${Level ?? 1} fw-bold w-100 py-1 px-5 rounded-pill bg-secondary text-light text-uppercase text-center ${className}`} style={{ ...style }}>
			{Icon && <MaterialIcon Icon={Icon} className='me-2' style={{ verticalAlign: 'text-top' }} />}
			{Text}
		</div>
	);
}

export default Heading;
