import { useEffect } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { logEvent } from 'firebase/analytics';
import { useAnalytics, useTitle } from '../App';
import { useProjectRoadmap } from '../Components/TopMenu';
import ProjectRoadmaps from '../Components/ProjectRoadmaps/_AllProjectRoadmaps';
import MaterialIcon from '../Components/MaterialIcon';

function DnDMobAttacker() {
	useTitle('D&D Mob Attacker');
	useProjectRoadmap(<ProjectRoadmaps.DnDMobAttacker />);
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'D&D Mob Attacker' });
	}, []);

	return (
		<>
			<Container fluid className='w-100 h-100 p-0'>
				<Row className='h-auto min-h-100 justify-content-center'>
					<Col md={10} lg={8} xl={6} className='p-4'>
						<Row className='justify-content-center mt-5'>
							<Col xs={'auto'} className='fade-in'>
								<Card bg='light' text='dark' className='text-center' style={{ maxWidth: '25rem' }}>
									<Card.Header className='p-3'>
										<Card.Title>
											<div className='fs-1'>D&D Mob Attacker</div>
										</Card.Title>
										<Card.Subtitle>
											<Row className='justify-content-center'>
												<Col xs={'auto'}>
													<Badge bg='mid' className='d-flex align-items-center'>
														<MaterialIcon Icon={'pending'} className={'me-1'} /> Future Migration
													</Badge>
												</Col>
											</Row>
										</Card.Subtitle>
									</Card.Header>
									<Card.Body>
										<Card.Text className='text-start'>This project is queued to be migrated to this site.</Card.Text>
										<Card.Text className='text-start'>
											Click the page title in the top bar to view this project's roadmap or follow the link below to take a look at it's current version.
										</Card.Text>
									</Card.Body>
									<Card.Footer>
										<Row className='justify-content-center p-2'>
											<Col xs={'auto'}>
												<Card.Link href='https://dnd-5e-calc.web.app/' target='_BLANK' className='w-auto text-decoration-none'>
													<span className='d-flex justify-content-center align-items-center'>
														View Current Version <MaterialIcon Icon={'open_in_new'} className={'ms-2'} />
													</span>
												</Card.Link>
											</Col>
										</Row>
									</Card.Footer>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default DnDMobAttacker;
