import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PaletteSwatch from './PaletteSwatch';
import { Button, Stack } from 'react-bootstrap';
import MaterialIcon from '../MaterialIcon';
import { getRandomHexValue, getRandomUUID, isNumber, toNumber } from '../../API/Utilities';

PaletteMenu.propTypes = {
	className: PropTypes.string,
	style: PropTypes.string,
	HandlePaletteChange: PropTypes.func,
	HandleFillClick: PropTypes.func,
	MaxSwatches: PropTypes.number,
};

function PaletteMenu({ className = '', style, HandlePaletteChange, HandleFillClick, MaxSwatches = 5 }) {
	const [paletteSwatches, paletteSwatches_set] = useState([{ id: getRandomUUID(), color: getRandomHexValue() }]);
	const [activeSwatch, activeSwatch_set] = useState(paletteSwatches[0]);
	const [activeSwatchPrev, activeSwatchPrev_set] = useState();

	useEffect(() => {
		HandlePaletteChange && typeof HandlePaletteChange === 'function' && HandlePaletteChange({ paletteSwatches, activeSwatch });
	}, [HandlePaletteChange, paletteSwatches, activeSwatch]);

	const handleFillSelect = useCallback(() => {
		HandleFillClick && typeof HandleFillClick === 'function' && HandleFillClick();
	}, [HandleFillClick]);

	const handleEraserSelect = useCallback(() => {
		if (activeSwatch) {
			activeSwatchPrev_set(activeSwatch);
			activeSwatch_set(undefined);
		} else if (activeSwatchPrev) {
			activeSwatch_set(activeSwatchPrev);
			activeSwatchPrev_set(undefined);
		}
	}, [activeSwatch, activeSwatchPrev]);

	const handleKeydown = useCallback(
		(e) => {
			if (e.repeat) return; // Ignore the event if the key is being held down

			const key = e.key && e.key.toLowerCase();
			if (isNumber(key)) {
				let newActiveSwatch = paletteSwatches[toNumber(key) - 1];
				activeSwatch_set(newActiveSwatch);
			}

			if (key === 'e') {
				handleEraserSelect();
			}

			if (key === 'f') {
				handleFillSelect();
			}
		},
		[paletteSwatches, handleEraserSelect, handleFillSelect]
	);

	const handleKeyup = useCallback(
		(e) => {
			const key = e.key && e.key.toLowerCase();
			if (key === 'e') {
				handleEraserSelect();
			}
		},
		[handleEraserSelect]
	);

	useEffect(() => {
		document.addEventListener('keydown', handleKeydown);
		document.addEventListener('keyup', handleKeyup);

		return () => {
			document.removeEventListener('keydown', handleKeydown);
			document.removeEventListener('keyup', handleKeyup);
		};
	}, [handleKeydown, handleKeyup]);

	const handleSwatchSelect = useCallback(
		({ e, swatch }) => {
			if (swatch && swatch.id !== activeSwatch?.id) {
				e.preventDefault();
				activeSwatch_set(swatch);
			}
		},
		[activeSwatch]
	);

	const handleSwatchChange = useCallback(
		({ newValue, swatch }) => {
			let swatches = [...paletteSwatches];

			let changedSwatch = swatches.find((s) => s.id === swatch.id);
			changedSwatch.color = newValue;
			paletteSwatches_set([...swatches]);
			activeSwatch_set(changedSwatch);
		},
		[paletteSwatches]
	);

	const handleAddSwatch = useCallback(() => {
		let swatches = [...paletteSwatches];
		let newSwatch = { id: getRandomUUID(), color: getRandomHexValue() };
		swatches.push(newSwatch);
		paletteSwatches_set([...swatches]);
		activeSwatch_set(newSwatch);
	}, [paletteSwatches]);

	const handleRemoveActiveSwatch = useCallback(() => {
		let swatches = [...paletteSwatches];
		if (activeSwatch && activeSwatch.id && swatches.find((s) => s.id === activeSwatch.id) !== undefined) {
			let newActiveSwatch;

			if (activeSwatch && activeSwatch.id) {
				let activeSwatchIndex = swatches.indexOf(swatches.find((s) => s.id === activeSwatch.id));

				swatches = swatches.filter((s) => s.id !== activeSwatch.id);

				let newActiveIndex = swatches[activeSwatchIndex - 1] ? activeSwatchIndex - 1 : swatches.length > 0 ? 0 : undefined;
				newActiveSwatch = swatches[newActiveIndex];
			}

			paletteSwatches_set([...swatches]);
			activeSwatch_set(newActiveSwatch);
		}
	}, [paletteSwatches, activeSwatch]);

	const activeColorIconRef = useRef();
	// useEffect(() => {
	// 	if (activeColorIconRef.current) {
	// 		activeColorIconRef.current.addLongPressListener(handleRemoveActiveSwatch);
	// 	}
	// }, [activeColorIconRef, handleRemoveActiveSwatch]);

	return (
		<>
			<div
				className={`PaletteMenu transform center-horizontal w-auto max-w-100 px-1 ${className}`}
				style={{ zIndex: 1000, '--swatch-size': '2.25rem', ...style }}
				onKeyDownCapture={(e) => {
					console.log(e.key);
				}}
			>
				<div
					className='text-light bg-dark-glass pt-2 pb-2 px-3'
					style={{
						borderRadius: 'var(--nmd-tk-border-radius) var(--nmd-tk-border-radius) 75rem 75rem',
					}}
				>
					<Stack direction='horizontal' gap={2}>
						<div>
							<div
								ref={activeColorIconRef}
								id='activeColorIcon'
								title={activeSwatch && activeSwatch.color ? 'This is the color / tool that is currently active.' : 'Eraser active'}
								className={`position-relative border border-2 overflow-hidden p-0`}
								style={{
									width: 'var(--swatch-size)',
									height: 'var(--swatch-size)',
									'--nmd-tk-border-color': 'var(--nmd-tk-body)',
									borderRadius: 'var(--nmd-tk-border-radius) var(--nmd-tk-border-radius) var(--nmd-tk-border-radius) 75%',
									backgroundColor: (activeSwatch && activeSwatch.color) ?? 'var(--nmd-tk-body)',
								}}
							>
								{!(activeSwatch && activeSwatch.color) && (
									<MaterialIcon
										Icon={'ink_eraser'}
										className={'transform center-both pe-none user-select-none'}
										style={{ fontSize: 'calc(var(--swatch-size) * 0.65)', '--translateY': '-60%', '--translateX': '-40%' }}
									/>
								)}

								{activeSwatch && activeSwatch.color && (
									<MaterialIcon
										Icon={'palette'}
										className={'transform center-both pe-none user-select-none text-center text-dark opacity-50'}
										style={{
											fontSize: 'calc(var(--swatch-size) * 0.65)',
											'--translateY': '-60%',
											'--translateX': '-40%',
										}}
									/>
								)}
							</div>
						</div>

						<hr className='vr my-0' />

						<div>
							<Button
								title={`Delete the active color and clear all tiles painted with it. This cannot be undone.`}
								variant='outline-danger'
								className={`position-relative overflow-hidden rounded-circle p-0 m-0`}
								style={{
									width: 'var(--swatch-size)',
									height: 'var(--swatch-size)',
								}}
								onClick={(e) => handleRemoveActiveSwatch(e)}
								disabled={!activeSwatch}
							>
								<MaterialIcon Icon={'format_color_reset'} className={'transform center-both'} style={{ fontSize: 'calc(var(--swatch-size) * 0.65)' }} />
							</Button>
						</div>

						{paletteSwatches && paletteSwatches.length > 0 && <hr className='vr my-0' />}

						{paletteSwatches && paletteSwatches.length > 0 && (
							<Stack direction='horizontal' gap={2} className='overflow-x-auto scrollbar-none'>
								{paletteSwatches.map((swatch, idx) => {
									return (
										<div key={swatch.id} className='position-relative'>
											<PaletteSwatch
												DefaultValue='#ffffff'
												CurrentValue={swatch.color}
												HandleValueChange={(newValue) => handleSwatchChange({ newValue, swatch })}
												HandleSwatchSelect={(e) => handleSwatchSelect({ e, swatch })}
											/>
											<div className='transform center-both pe-none user-select-none text-center text-dark fs-5 opacity-50'>{idx + 1}</div>
										</div>
									);
								})}
							</Stack>
						)}

						<hr className='vr my-0' />

						<div>
							<Button
								title={`Click to make the eraser active. Hold 'e' to temporarily activate.`}
								variant='outline-light'
								className={`position-relative overflow-hidden rounded-circle p-0 m-0`}
								style={{
									width: 'var(--swatch-size)',
									height: 'var(--swatch-size)',
								}}
								onClick={(e) => handleEraserSelect(e)}
							>
								<MaterialIcon Icon={'ink_eraser'} className={'transform center-both'} style={{ fontSize: 'calc(var(--swatch-size) * 0.65)' }} />
							</Button>
						</div>

						<div>
							<Button
								title={`Fill all empty tiles with the active color`}
								variant='outline-light'
								className={`position-relative overflow-hidden rounded-circle p-0 m-0`}
								style={{
									width: 'var(--swatch-size)',
									height: 'var(--swatch-size)',
								}}
								onClick={(e) => handleFillSelect(e)}
							>
								<MaterialIcon Icon={'format_color_fill'} className={'transform center-both'} style={{ fontSize: 'calc(var(--swatch-size) * 0.65)' }} />
							</Button>
						</div>

						<hr className='vr my-0' />

						<div>
							<Button
								title={`Add a new color to your palette (Up to ${MaxSwatches})`}
								variant='outline-primary'
								className={`position-relative overflow-hidden p-0`}
								style={{
									width: 'var(--swatch-size)',
									height: 'var(--swatch-size)',
									borderRadius: 'var(--nmd-tk-border-radius) var(--nmd-tk-border-radius) 75% var(--nmd-tk-border-radius)',
								}}
								onClick={(e) => handleAddSwatch(e)}
								disabled={Object.keys(paletteSwatches).length >= MaxSwatches}
							>
								<MaterialIcon
									Icon={'add'}
									className={'transform center-both'}
									style={{ fontSize: 'calc(var(--swatch-size) * 0.75)', '--translateY': '-55%', '--translateX': '-55%' }}
								/>
							</Button>
						</div>
					</Stack>
				</div>
			</div>
		</>
	);
}

export default PaletteMenu;
