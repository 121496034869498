import { ButtonGroup, Col, FormLabel, Row, ToggleButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { generateRandomElementId } from '../../API/Utilities';
import { useEffect, useState } from 'react';

PanelRadioButton.propTypes = {
	IconPosition: PropTypes.oneOf(['start', 'end']),
	Options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })).isRequired,
};

function PanelRadioButton({ className = '', style, variant = 'outline-primary', size, Label, Options, DefaultValue, CurrentValue, HandleValueChange }) {
	const inputId = generateRandomElementId();

	const [radioValue, radioValue_set] = useState(DefaultValue ?? (Options && Options.length > 0 ? Options[0].value : undefined) ?? '');

	const radioChanged = (newValue) => {
		radioValue_set(newValue);
		HandleValueChange && typeof HandleValueChange === 'function' && HandleValueChange(newValue);
	};

	useEffect(() => {
		radioValue_set(CurrentValue ?? DefaultValue);
	}, [CurrentValue, DefaultValue]);

	return (
		<Row className={`my-3 ${className}`} style={{ ...style }}>
			<Col>
				<FormLabel className='w-100 m-0 my-1 px-1' htmlFor={inputId}>
					<Row>
						<Col>
							<span className='user-select-none'>{Label}</span>
						</Col>
					</Row>
				</FormLabel>

				{Options && Options.length > 0 && (
					<ButtonGroup className={`w-100 text-uppercase px-1`}>
						{Options.map((radio, idx) => (
							<ToggleButton
								key={idx}
								id={`radio-${idx}`}
								type='radio'
								variant={variant}
								size={size}
								className={idx === 0 ? 'rounded-start-pill' : idx === Options.length - 1 ? 'rounded-end-pill' : ''}
								name={inputId}
								value={radio.value}
								checked={radioValue === radio.value}
								onChange={(e) => radioChanged(e.currentTarget.value)}
							>
								{radio.label}
							</ToggleButton>
						))}
					</ButtonGroup>
				)}
			</Col>
		</Row>
	);
}

export default PanelRadioButton;
